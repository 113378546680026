.s-total-body{
    width: 100%;
    background: rgb(247 247 247);
    padding: 0px 15px 0px 15px;
}

.s-title{
    font-size: 1.4rem;
    font-weight: 400;
}


.s-total{
    font-size: 2.4rem;
    font-weight: 400;
    color: #0078d4;
}

.price-item-editable > input {
    width: 90px;
}