.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swal2-container{
    z-index: 4201 !important;
}


.p-galleria .p-galleria-thumbnail-container {
    background-color: white !important;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev, .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next{
        color: #000000 !important;
}

.item-image .percent{
    position: absolute;
    background: #f4622e;
    color: white;
    margin: 15px 0 0px 0px;
    padding: 2px;
    font-size: 13px;
    font-weight: 700;
}
.item-image{
    max-height: 220px;
    overflow: hidden;
}
/* .item-price{
    text-align: center;
} */
.item-price .last-price{
    padding: 0px;
    margin: 0px;
    text-decoration: line-through;
    font-size: 14px;
    color: #7c7c7c;
}
.item-price .new-price{
    padding: 0px;
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
    color: red;
}

.item-description{
    min-height: 32px;
    max-height: 32px;
    overflow: hidden;
}

  /* .text-red {
    color: #e52c2c;
}   */

.due-text {
    background: #ffcdd2;
    color: #c63737;
    padding: 1px 5px 1px 5px;
    font-weight: 700;
}

.no-due-text {
    background: #c8e6c9;
    color: #256029;
    padding: 1px 5px 1px 5px;
    font-weight: 700;
}

.custom-file-upload {
    border-radius: 2px;
    display: inline-block;
    border: 1px solid rgb(0, 101, 159);
    color: rgb(0, 110, 173);
    cursor: pointer;
    width: 100%;
    text-align: center;
    padding: 5px 0px 7px 0px;
    margin-top: 5px;
    cursor: pointer;
  }

  .custom-file-upload input[type="file"]  {
    display: none;
  }
.custom-file-upload:hover{
    background: #efefef;
  }

.suscription-circle{
  background: #000000;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  box-shadow: 1px 0px 5px 0px rgba(209,209,209,0.75);
-webkit-box-shadow: 1px 0px 5px 0px rgba(209,209,209,0.75);
-moz-box-shadow: 1px 0px 5px 0px rgba(209,209,209,0.75);
}

.yellow{
  background-color: rgb(255 209 32);
}

.red{
  background-color: rgb(188, 3, 0);
}

.green{
  background-color: rgb(34, 188, 0);
}

.pvtAxisContainer, .pvtVals{
    border: 1px solid #f1f1f1 !important;
    background: #fdfdfd !important;
    padding: 5px;
    min-width: 20px;
    min-height: 20px;
}

table.pvtTable thead tr th, table.pvtTable tbody tr th{
background-color: #f8f8f8 !important;
    border: 1px solid #ebebeb !important;
    font-size: 10pt !important;
    font-weight: 400 !important;
    padding: 5px !important;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
}
.pvtAxisContainer li span.pvtAttr{
    background: #ffffff !important;
    border: 1px solid #c8d4e3  !important;
    border-radius: 0px !important;
  }


.pvtTable .pvtAxisLabel,.pvtTable .pvtTotalLabel{
  color: #000000;
  font-size: 13px !important;
  font-weight: 600!important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
  
}

.pvtTable  .pvtTotal,.pvtTable .pvtGrandTotal{
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
  font-size: 13px !important;
  font-weight: 600!important;
  color: #000000;
}

.pvtDropdownCurrent{
  width: 100px !important;
  border-radius: 0px !important;
      border: 1px solid #e0e0e0 !important;
}


table.pvtTable tbody tr td{
  border: 1px solid #f1f1f1 !important;
}

table.pvtTable tbody tr td {
   border: 1px solid #f1f1f1  !important;
}